import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ctl from "@netlify/classnames-template-literals";
import { validationSchema } from "utils/validationSchema";
import { Button, Container, Input, Text } from "components";

const ContactForm = () => {
  const onSubmit = async data => {
    try {
      await fetch(`/api/contact`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(res => res.json())
        .then(data => {
          const { error, body } = data ?? {};
          if (error) {
            alert("Something went wrong, kindly try again");
          }
          if (body) {
            alert(
              "Successful! We got your information, we will reach out soon"
            );
            reset();
          }
        })
        .catch(error => {
          alert("Something went wrong, kindly try again");
        });
    } catch (error) {
      alert("Something went wrong, kindly tray ");
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(validationSchema[2]) });

  return (
    <Container>
      <section className={sectionWrapperStyle}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col justify-center"
        >
          <Text
            variant="h5"
            value="Submit your message"
            className={headerStyle}
          />
          <Input
            required
            register={register("firstName")}
            error={errors.firstName?.message}
            label="First Name"
            placeholder="What's your first name?"
          />
          <Input
            label="Last Name"
            register={register("lastName")}
            error={errors.lastName?.message}
            required
            placeholder="What's your last name?"
          />
          <Input
            label="Email Address"
            register={register("email")}
            error={errors.email?.message}
            required
            placeholder="What's your email?"
          />
          <Input
            label="Company Name"
            variant="textarea"
            register={register("company")}
            error={errors.company?.message}
            required
            placeholder="What's your Company name?"
          />
          <Input
            label="Message"
            register={register("message")}
            error={errors.message?.message}
            required
            placeholder="Drop your message here"
          />

          <div className="lg:mt-[60px] mt-8">
            <Button isLoading={isSubmitting} text="Submit" size="large" />
          </div>
        </form>
      </section>
    </Container>
  );
};

const sectionWrapperStyle = ctl(`
py-8
lg:py-[113px]
max-w-[501px]
mx-auto
`);
const headerStyle = ctl(`
mb-[70px] 
lg:mb-8
`);
export { ContactForm };
