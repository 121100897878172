import React from "react";
import { PageHeaderOne, Br } from "components";

const ContactUsHeader = () => {
  return (
    <PageHeaderOne
      heading={
        <>
          Get in touch <Br on="mobile" />
          with Us
        </>
      }
      paragraph={
        <>
          Do you have questions about our training and overall services? Contact
          our <Br on="desktop" />
          customer service team by completing the contact form. We'll get back
          to you shortly.
        </>
      }
    />
  );
};

export { ContactUsHeader };
