import React from "react";

import { Layout } from "components";
import { ContactUsHeader, ContactForm } from "templates/contact-us";

const ContactUs = () => (
  <Layout title="Contact Us">
    <ContactUsHeader />
    <ContactForm />
  </Layout>
);

export default ContactUs;
